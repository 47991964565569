/**
 * Creates an event listener that will react to the first event
 * and after that, only every `limit` milliseconds.
 * The function was made to throttle default cancellable events.
 * See js/main.site.js for how this function is used.
 *
 * @param limit throttling amount in milliseconds
 * @returns {function(*): void} throttled default event listener
 */
window.throttledDefaultListener = (limit) => {
    let waiting = false;
    return function (event) {
        if (waiting) {
            event.preventDefault();
        } else {
            waiting = true;
            setTimeout(function () {
                waiting = false;
            }, limit);
        }
    };
};

window.clickAndDisable = (link) => {
    // Disable subsequent clicks
    link.onclick = function (event) {
        event.preventDefault();
        setTimeout(function () {
            link.onclick = function () {};
        }, 2500);
    };
};

window.openInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
};

window.getUrlParameterByName = (unescapedName) => {
    // TODO: comment
    var name = unescapedName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

window.csrftoken = () => {
    // Return csrf token from cookie
    return Cookies.get('csrftoken');
};

window.addNext = (id) => {
    // TODO: comment
    var href = $('#' + id).attr('href');
    $('#' + id).attr('href', href + '?next=' + location.pathname);
};
