$(function () {
    $('body').bind('UKFileUploaded', function (event) {
        var fid = $.parseJSON(event.response.response);
        $('input[name="csrfmiddlewaretoken"]').append('<input type="hidden" name="file" value="' + fid.fid + '">');
    });
});

var progress = function (p) {
    $('#video-form').removeClass('hide');
    return (
        '<div class="progress progress-striped active"><div class="bar" style="width: ' +
        p +
        '%;">' +
        p +
        '% uploaded</div></div>'
    );
};

var complete = function (fpfiles) {
    $('#video-form-submit').removeAttr('disabled');
    return (
        '<div class="progress"><div class="bar bar-success" style="width: 100%;">' +
        fpfiles.filename +
        ' Uploaded</div></div>'
    );
};

var error = function (type, message) {
    return (
        '<div class="progress"><div class="bar bar-error" style="width: 100%;">(' +
        type +
        ')' +
        message +
        '</div></div>'
    );
};

$('#select-file').click(function (e) {
    $('#fupload').click();
});

if (typeof filepicker != 'undefined') {
    filepicker.setKey('APnNRiZKsRUCFGkMvX40hz');

    filepicker.makeDropPane($('#exampleDropPane')[0], {
        multiple: false,
        mimetype: 'video/*',

        dragEnter: function () {
            $('#exampleDropPane').html('Drop to upload').css({
                backgroundColor: '#E0E0E0',
                border: '1px solid #000',
            });
        },
        dragLeave: function () {
            $('#exampleDropPane').html('Drop files here').css({
                backgroundColor: '#F6F6F6',
                border: '1px dashed #666',
            });
        },
        onSuccess: function (fpfiles) {
            $("input[name='file']").attr('value', fpfiles[0].url);
            $('#uploader-well').html(complete(fpfiles[0]));
        },
        onError: function (type, message) {
            $('#exampleDropPane').html(error(type, message));
        },
        onProgress: function (percentage) {
            $('#exampleDropPane').html(progress(percentage));
        },
    });
}
$('#fupload').change(function () {
    var uploadFile = $('#fupload');
    if (!uploadFile[0].value) {
        alert('Choose a file');
    } else {
        filepicker.store(
            uploadFile[0],
            function (fpfiles) {
                $("input[name='file']").attr('value', fpfiles.url);
                $("input[name='filename']").attr('value', fpfiles.filename);
                $("input[name='size']").attr('value', fpfiles.size);
                $('#uploader-well').html(complete(fpfiles));
            },
            function (type, message) {
                $('#exampleDropPane').html(error(type, message));
            },
            function (percentage) {
                $('#exampleDropPane').html(progress(percentage));
            },
        );
    }
});
