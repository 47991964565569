$(document).ready(() => {
    const url = window.location.href.split('?')[0];
    $('a.filters__item')
        .filter(function () {
            return this.href == url;
        })
        .addClass('active');

    $('a.filters__item').on('click', (event) => {
        const clickedElement = $(event.currentTarget);
        clickedElement.siblings('.filters__item.active').removeClass('active');
        clickedElement.addClass('active');
    });
});
